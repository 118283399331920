import React, { useState, useEffect } from "react";
import styles from "./About.module.scss";
import client from "../../client";

const About = () => {
  const [authorData, setAuthorData] = useState(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "author"] {
          name,
          bio
        }[0]`
      )
      .then((data) => setAuthorData(data))
      .catch(console.error);
  }, []);

  const renderBioText = (nodes) => {
    return nodes
      .map((node) => {
        if (node._type === "span" && node.text) {
          return node.text;
        } else if (node.children) {
          return renderBioText(node.children);
        }
        return null;
      })
      .join(" ");
  };

  const authorName = authorData?.name || "";
  const authorSurname = authorName.split(" ")[1] || "";
  const authorFirstName = authorName.split(" ")[0] || "";

  return (
    <div className={styles.wrapperAbout}>
      <div className={styles.aboutContent}>
        <div className={styles.aboutImage}></div>
        <div className={styles.aboutText}>
          {authorData && (
            <>
              <h1 className={styles.aboutName}>
                {authorFirstName}
                <br />
                <span className={styles.aboutSurname}>{authorSurname}</span>
              </h1>
              <div className={styles.aboutParagraph}>
                {authorData.bio && renderBioText(authorData.bio[0].children)}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.aboutImageLine}></div>
    </div>
  );
};

export default About;
