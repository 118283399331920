import styles from "./Contact.module.scss";
import Logo from "../../assets/icons/logo_sygnet_white 1.png";
import { useEffect, useState } from "react";
import client from "../../client";

const Contact = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "contact"] {
          _id,
          header,
          email,
          nameComapany,
          nip,
          adress,
          city,
        }`
      )
      .then((data) => setContacts(data))
      .catch(console.error);
  }, []);

  return (
    <div className={styles.wrapperContact}>
      {contacts.map((contact) => (
        <div key={contact._id}>
          <h1 className={styles.contactTitle}>{contact.header}</h1>
          <div className={styles.wrapperContactContent}>
            <div className={styles.wrapperContactEmail}>
              <p className={styles.contactEmail}>{contact.email}</p>
            </div>

            <p className={styles.wrapperContactData}>{contact.nameComapany}</p>
            <p className={styles.wrapperContactData}>{contact.nip}</p>
            <p className={styles.wrapperContactData}>
              {contact.adress}
              <span className={styles.contactAdress}> {contact.city}</span>
            </p>
          </div>
        </div>
      ))}

      <div className={styles.wrapperFooter}>
        <div className={styles.wrapperFooterText}>
          <p>MCpro 2021 All Rights Reserved.</p>
        </div>

        <div className={styles.imgContact}>
          <img className={styles.img} src={Logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
