import Box from "../../components/Box/Box";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import styles from "./Offer.module.scss";
import client from "../../client";

const Offer = () => {
  const [offers, setOffers] = useState([]);
  const [offersHeader, setOffersHEadaer] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "offer"] {
          _id,
          title,
          contentOffer,
        }`
      )
      .then((data) => setOffers(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "offerHeader"] {
          _id,
          title,
          description,
        }`
      )
      .then((data) => setOffersHEadaer(data[0]))
      .catch(console.error);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    useCSS: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.offerWrapper}>
      <h1 className={styles.aboutName}>{offersHeader.title}</h1>
      <p className={styles.homeParagraph}>
        {offersHeader.description &&
          offersHeader.description.map((block, index) => (
            <span key={index}>
              {block.children.map((span) => span.text).join(" ")}
              <br />
            </span>
          ))}
      </p>

      <div className={styles.offerWrapperLine}>
        <div className={styles.offerWrapperLineTwo}>
          <div className={styles.box}>
            <Slider {...settings}>
              {offers.map((offer) => (
                <div key={offer._id}>
                  <Box
                    titleOffer={offer.title}
                    contentOffer={offer.contentOffer[0].children[0].text}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
