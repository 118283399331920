import React, { useEffect, useState } from "react";
import Content from "../../components/navigations/Content/Content";
import styles from "./Home.module.scss";
import { Link } from "react-scroll";
import client from "../../client";

function Home() {
  const [headerPage, setheaderPage] = useState({ title: "" });
  useEffect(() => {
    client
      .fetch(
        `*[_type == "headerPage"] {
          _id,
          title,
          description,
          subTitle,
        }`
      )
      .then((data) => setheaderPage(data[0] || { title: "" })) // Domyślna wartość dla title, jeśli data[0] jest puste
      .catch(console.error);
  }, []);

  // Sprawdzamy, czy headerPage.title istnieje, zanim użyjemy split
  const titleParts = headerPage.title ? headerPage.title.split(" ") : [];

  const descriptionBlocks =
    headerPage.description &&
    headerPage.description.map((block, index) => (
      <span key={index}>
        {block.children.map((span) => span.text).join(" ")}
        <br />
      </span>
    ));

  return (
    <>
      <div className={styles.home_container}>
        <div className={styles.home_content}>
          <Content claretTitle={titleParts[0]} />
          <div className={styles.titleHome}>
            <Content claretTitle={titleParts[1]} />
          </div>

          <div className={styles.homeParagraph}>{descriptionBlocks}</div>

          <Link
            activeClass={styles.active}
            className={styles.naviagtionList__element}
            to="contact"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <p className={styles.homeParagraphContact}>{headerPage.subTitle}</p>
          </Link>
        </div>
        <div className={styles.home_image}></div>
      </div>
    </>
  );
}

export default Home;
